<template>
    <data-loading-animation v-if="dataLoading"/>
    <div v-else class="bg-4 py-4 m-0">
        <div class="row m-0">
            <div class="col-4">
                <category-count title="Published Bulls Count" :count="details['published']"/>
            </div>
            <div class="col-4">
                <category-count title="Unpublished Bulls Count" :count="details['unpublished']"/>
            </div>
            <div class="col-4">
                <category-count title="Total Bulls Count" :count="details['bull-total']"/>
            </div>
            <div class="col-4">
                <category-count title="Breeds Count" :count="details.breed"/>
            </div>
            <div class="col-4">
                <category-count title="Species Count" :count="details.species"/>
            </div>
        </div>
    </div>
</template>

<script>
import CategoryCount from '@components/ui/CategoryCount';
import axios from 'secure-axios';
import urls from '../data/urls';

export default {
    name: 'Dashboard',
    components: { CategoryCount },
    data () {
        return {
            dataLoading: false,
            details: ''
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            const that = this;
            that.dataLoading = true;
            const response = await axios.get(urls.admin.dashboard);
            const json = response.data;
            if (json.success === 'true') {
                that.details = json.data;
            } else {
                that.$notify('Details not found, Please try again later.', 'No Data Found', {
                    type: 'info'
                });
            }
            that.dataLoading = false;
        }
    }
};
</script>

<style scoped>

</style>
