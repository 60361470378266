<template>
    <div class="text-center fl-y fl-y-cc py-4 card mb-3">
        <h5 class="mb-3">{{title}}</h5>
        <div class="w-50 card text-center border-primary bg-secondary text-black-50">
            <span class="font-weight-bold text-md text-white lt-sp-1">{{count}}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CategoryCount',
    props: {
        title: {
            type: String,
            default: ''
        },
        count: {
            type: Number,
            default: 0
        }
    }
};
</script>

<style scoped>
.bg-dark-green{
    background: #1E8449;
    color:var(--color-white);
}
.text-md{
    font-size: 1.4rem;
}
</style>
