<template>
    <div class="">
        <div class="container full-height p-4 fl-x fl-j-c fl-a-s text-black-50">
            <div class="card contact-form py-4 text-black-50">

                <s-form key="'update" role="form" @submit="changePassword"
                        class="form-sm" ref="form">
                    <div class="fl-x fl-j-c">
                        <img src="../../assets/img/logo/logo.png" class="logo-img filter-invert mb-4 px-5" alt="">
                    </div>
                    <div class="text-center">
                        <h4 class="text-center text-primary mb-4">Update Password</h4>
                    </div>
                    <validated-input label="Enter Old Password" type="password" name="old_password"
                                     v-model="model.old_password" class="text-secondary"
                                     :disabled="loading" :rules="rules.old_password"/>
                    <validated-input label="Enter New Password" name="new_password" type="password"
                                     v-model="model.new_password" class="text-secondary"
                                     :disabled="loading" :rules="rules.old_password"/>
                    <validated-input label="Confirm New Password" type="password" name="confirm_password"
                                     v-model="model.confirm_password" class="text-secondary"
                                     :disabled="loading" :rules="rules.confirm_password"/>
                    <div class="mt-3 text-center fl-x fl-j-c px-4">

                        <btn color="primary" class=" px-5" block text="Update Password" :loading="loading"
                             loading-text="Updating Password..."/>
                    </div>
                </s-form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'UpdatePassword',
    data () {
        return {
            loading: false,
            model: {
                old_password: '',
                new_password: '',
                confirm_password: ''
            },
            rules: {
                old_password: {
                    required: true
                },
                new_password: {
                    required: true
                },
                confirm_password: {
                    required: true
                }
            }
        };
    },
    methods: {
        async changePassword () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.auth.updatePassword, that.model);
            const json = response.data;
            if (json.success === true) {
                that.formSuccess();
                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        formSuccess () {
            this.$router.push('/login/');
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        }
    }
};
</script>

<style scoped>
.logo-img {
    width: 16.75rem;
}
</style>
